var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "verify-staff" },
    [
      !_vm.isImport
        ? [
            _c("HeaderBack", {
              attrs: { title: _vm.navName },
              on: {
                back: function ($event) {
                  return _vm.$emit("goBack")
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "left",
                    fn: function () {
                      return [
                        _c("span", { staticClass: "company-name" }, [
                          _vm._v(_vm._s(_vm.info.companyName || "申报主体")),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1337350405
              ),
            }),
            _c("CbNumStatistics", {
              staticClass: "Statistics",
              attrs: { "is-tabs": true, "statistics-info": _vm.statisticsInfo },
              on: { changeTab: _vm.changeTab },
            }),
          ]
        : _vm._e(),
      !_vm.isImport
        ? _c(_vm.tableType, {
            tag: "component",
            attrs: {
              "only-read": _vm.onlyRead,
              "screen-roster-list": _vm.screenRosterList,
              "subject-ids": _vm.subjectIds,
            },
            on: {
              importTable: _vm.importTable,
              getPersonnelStatistics: _vm.getPersonnelStatistics,
            },
          })
        : _vm._e(),
      _vm.isImport
        ? _c("BatchImport", {
            attrs: {
              "extra-params": Object.assign({}, _vm.subjectIds, {
                type: _vm.type,
              }),
              title: "人员报送批量导入",
              "import-template": "importTaxFilingInfoList",
            },
            on: {
              goBack: function ($event) {
                _vm.isImport = false
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }